import { useRef, MutableRefObject, useEffect } from 'react';
import { useTrail, SpringConfig } from 'react-spring';
import { useOnScreen } from './useOnScreen';

export const useSlideInAnimation = <T extends HTMLElement>({
  amount,
  wrapperRef: externalWrapperRef,
  delay = 0,
}: {
  amount: number;
  wrapperRef?: MutableRefObject<T | null>;
  delay?: number;
}) => {
  const internalWrapperRef = useRef<T>(null);
  const wrapperRef = externalWrapperRef || internalWrapperRef;
  const onScreen = useOnScreen(wrapperRef, {
    threshold: 0.25,
  });

  const [trails, api] = useTrail(amount, () => ({
    from: { opacity: 0, y: -40 },
    y: 0,
    opacity: 1,
    reset: true,
    pause: true,
    config,
  }));

  useEffect(() => {
    const t = setTimeout(() => onScreen && api.resume(), delay);
    return () => {
      clearTimeout(t);
    };
  }, [api, delay, onScreen]);

  return [wrapperRef, trails] as const;
};

const config: SpringConfig = {
  mass: 5,
  tension: 3000,
  friction: 200,
};
