import React from 'react';
import { Icon, IconProps } from './Icon';

export type RedditIconProps = IconProps;

const RedditIcon = (props: RedditIconProps) => (
  <Icon viewBox="0 0 42 41" fill="none" {...props}>
    <path
      d="M21.253 40.241c-10.836 0-19.62-8.784-19.62-19.62C1.632 9.784 10.416 1 21.252 1s19.62 8.784 19.62 19.62c0 10.837-8.784 19.621-19.62 19.621z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <path
      d="M33.629 19.797c0-1.454-1.2-2.635-2.673-2.635-.72 0-1.372.285-1.852.743-1.821-1.186-4.285-1.941-7.014-2.038l1.492-4.65 4.041.936-.006.058c0 1.189.981 2.155 2.188 2.155 1.205 0 2.185-.966 2.185-2.155 0-1.188-.981-2.155-2.185-2.155-.926 0-1.715.571-2.034 1.373l-4.356-1.01a.373.373 0 00-.442.247l-1.665 5.187c-2.855.034-5.442.795-7.345 2.017a2.674 2.674 0 00-1.81-.709c-1.474 0-2.672 1.182-2.672 2.636 0 .966.536 1.804 1.325 2.262-.053.28-.087.565-.087.854 0 3.895 4.838 7.065 10.786 7.065 5.947 0 10.786-3.17 10.786-7.065 0-.273-.03-.542-.076-.807.837-.446 1.414-1.307 1.414-2.31zm-17.33 1.809c0-.865.714-1.57 1.591-1.57.878 0 1.591.705 1.591 1.57 0 .864-.713 1.568-1.59 1.568-.878 0-1.592-.704-1.592-1.568zm9.117 4.65c-.802.79-2.061 1.175-3.848 1.175l-.013-.003-.013.003c-1.788 0-3.047-.384-3.848-1.174a.365.365 0 010-.521.377.377 0 01.53 0c.653.644 1.739.957 3.318.957l.013.003.013-.003c1.579 0 2.664-.314 3.318-.958a.378.378 0 01.53 0 .366.366 0 010 .522zm-.19-3.082c-.878 0-1.591-.704-1.591-1.568 0-.865.713-1.57 1.59-1.57.878 0 1.591.705 1.591 1.57 0 .864-.713 1.568-1.59 1.568z"
      fill="currentColor"
    />
  </Icon>
);

export { RedditIcon };
