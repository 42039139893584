import React from 'react';
import styled, { css } from 'styled-components';
import { Footer } from '../components';
import {
  TopSection,
  SecondSection,
  ThirdSection,
  FourthSection,
  FifthSection,
} from '../components/sections';

const MainPage = () => (
  <Wrapper>
    <TopSection />
    <SecondSection />
    <ThirdSection />
    <FourthSection />
    <FifthSection />
    <Footer />
  </Wrapper>
);

const Wrapper = styled.main`
  min-height: 100vh;
  background: ${({ theme: { getColor } }) => css`
    linear-gradient(
      135.39deg,
        ${getColor('brightGray')} -0.7%,
      ${getColor('woodSmoke')} 100%
    ),
  linear-gradient(134.78deg, ${getColor('armadillo')} -8.57%, ${getColor(
    'codGray',
  )} 104.3%);
  `};
`;

export { MainPage };
