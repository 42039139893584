import React from 'react';
import { AppProviders } from './providers/AppProviders';
import { MainPage } from './pages';

const App = () => (
  <AppProviders>
    <MainPage />
  </AppProviders>
);

export default App;
