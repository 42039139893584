import React from 'react';
import { Icon, IconProps } from './Icon';

export type TelegramIconProps = IconProps;

const TelegramIcon = (props: TelegramIconProps) => (
  <Icon viewBox="0 0 42 41" fill="none" {...props}>
    <path
      d="M21 40.241c-10.836 0-19.62-8.784-19.62-19.62C1.38 9.784 10.163 1 21 1s19.62 8.784 19.62 19.62c0 10.837-8.784 19.621-19.62 19.621z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <path
      d="M17.587 24.034l-.353 5.009c.504 0 .723-.22.984-.482l2.364-2.282 4.898 3.624c.898.506 1.531.24 1.773-.835l3.215-15.223h.001c.285-1.342-.48-1.867-1.355-1.538l-18.897 7.311c-1.29.506-1.27 1.232-.22 1.562l4.832 1.518 11.222-7.096c.528-.353 1.008-.157.613.196l-9.078 8.236z"
      fill="currentColor"
    />
  </Icon>
);

export { TelegramIcon };
