import React, { FC } from 'react';
import { Styles } from './Styles';
import { Modals } from './Modals';

const AppProviders: FC = ({ children }) => (
  <Styles>
    <Modals>{children}</Modals>
  </Styles>
);

export { AppProviders };
