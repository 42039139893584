import React from 'react';
import styled from 'styled-components';
import { pxToStatic19Rem } from '../../styles';
import { TradeCards, CardsGeneration } from '../blocks';

const FifthSection = () => (
  <Wrapper>
    <TradeCards />
    <CardsGeneration />
  </Wrapper>
);

const Wrapper = styled.section`
  padding: ${pxToStatic19Rem(94)} ${pxToStatic19Rem(124)}
    ${pxToStatic19Rem(116)} ${pxToStatic19Rem(108)};

  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    padding: ${pxToStatic19Rem(64)} ${pxToStatic19Rem(45)}
      ${pxToStatic19Rem(64)};
  }
`;

export { FifthSection };
