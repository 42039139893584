import React from 'react';
import styled from 'styled-components';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export type IconProps = SvgIconProps;

const Icon = ({ children, ...props }: IconProps) => (
  <Svg {...props}>{children}</Svg>
);

const Svg = styled(SvgIcon)`
  max-width: 100%;
  max-height: 100%;
  fill: none;
  color: #fff;
`;

export { Icon };
