import React from 'react';
import { Icon, IconProps } from './Icon';

export type MediumIconProps = IconProps;

const MediumIcon = (props: MediumIconProps) => (
  <Icon viewBox="0 0 41 41" fill="none" {...props}>
    <path
      d="M22.175 21.181c0 4.335-3.54 7.848-7.905 7.848-4.366 0-7.905-3.513-7.905-7.848 0-4.334 3.539-7.848 7.905-7.848s7.905 3.514 7.905 7.848zM30.848 21.18c0 4.08-1.77 7.388-3.953 7.388s-3.953-3.309-3.953-7.389 1.77-7.388 3.953-7.388 3.953 3.307 3.953 7.388zM34.394 21.18c0 3.654-.622 6.619-1.39 6.619-.768 0-1.39-2.964-1.39-6.62 0-3.654.622-6.618 1.39-6.618.767 0 1.39 2.963 1.39 6.619z"
      fill="currentColor"
    />
    <path
      d="M20.38 40.241C9.542 40.241.758 31.457.758 20.621.749 9.784 9.533 1 20.379 1 31.215 1 40 9.784 40 20.62c0 10.837-8.784 19.621-19.62 19.621z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
  </Icon>
);

export { MediumIcon };
