import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import YouTube from 'react-youtube';
import { pxToRem } from '../../styles';
import { mainPageVideoId } from '../../utils/consts';
import { Text as DefText, TextProps } from '../texts';

const VideoBlock = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <FirstPart>
        <Title component={'h2'}>
          <span>{t('INDEX_PAGE_VIDEO_BLOCK__title1')}</span>
          {t('INDEX_PAGE_VIDEO_BLOCK__title2')}
        </Title>
        <Text paragraph>
          {t('INDEX_PAGE_VIDEO_BLOCK__text1')}{' '}
          <Span>{t('INDEX_PAGE_VIDEO_BLOCK__text2')}</Span>{' '}
          {t('INDEX_PAGE_VIDEO_BLOCK__text3')}{' '}
          <Span>{t('INDEX_PAGE_VIDEO_BLOCK__text4')}</Span>.{' '}
          {t('INDEX_PAGE_VIDEO_BLOCK__text5')}{' '}
          <Span>{t('INDEX_PAGE_VIDEO_BLOCK__text6')}</Span>{' '}
          {t('INDEX_PAGE_VIDEO_BLOCK__text7')}{' '}
          <Span>{t('INDEX_PAGE_VIDEO_BLOCK__text8')}</Span>{' '}
          {t('INDEX_PAGE_VIDEO_BLOCK__text9')}
        </Text>
        {/*<Text paragraph>{t('INDEX_PAGE_VIDEO_BLOCK__text10')}</Text>*/}
      </FirstPart>
      <SecondPart>
        <YouTube
          videoId={mainPageVideoId}
          containerClassName={'main-page-video'}
        />
      </SecondPart>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: flex;
  align-items: center;
  padding: ${pxToRem(104)} ${pxToRem(96)} ${pxToRem(94)} ${pxToRem(90)};
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    flex-direction: column;
    padding: ${pxToRem(50)} ${pxToRem(74)} ${pxToRem(35)};
  }
  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    flex-direction: column;
    padding-left: ${pxToRem(44)};
    padding-right: ${pxToRem(44)};
  }
  ${({ theme: { getDownMedia } }) => getDownMedia(500)} {
    flex-direction: column;
    padding-left: ${pxToRem(26)};
    padding-right: ${pxToRem(26)};
  }
`;
const FirstPart = styled.div`
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    padding: 0 ${pxToRem(19)};
  }
  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    padding: 0;
  }
`;
const Title = styled(DefText)`
  margin-bottom: ${pxToRem(21)};
  font-size: ${pxToRem(30.4)};
  line-height: 1.31578947;
  color: #fff;
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    font-size: ${pxToRem(24)};
  }

  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    font-size: ${pxToRem(22)};
  }

  span {
    display: block;
    font-size: ${pxToRem(39.2)};
    line-height: 1.02040816;
    font-variation-settings: 'wght' 800;
    color: ${({ theme: { getColor } }) => getColor('galliano')};

    ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
      font-size: ${pxToRem(30)};
    }

    ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
      font-size: ${pxToRem(26)};
    }
  }
`;
const Text = styled(DefText)`
  span {
    font-variation-settings: 'wght' 900;
  }
`;
const Span = styled((props: TextProps) => (
  <DefText component={'span'} {...props} />
))`
  font-weight: 700;
  font-variation-settings: 'wght' 700;
  color: ${({
    theme: {
      palette: {
        primary: { main },
      },
    },
  }) => main};
`;
const SecondPart = styled.div`
  flex: 0 0 60%;
  padding: 14px;
  margin-left: ${pxToRem(70)};
  border-radius: 15px;
  background: linear-gradient(118.09deg, #929185 -135.08%, #25292c 90.37%);
  box-shadow: 0 0 50px ${({ theme: { getColor } }) => getColor('black')};

  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    width: 100%;
    margin-top: ${pxToRem(22)};
    margin-left: 0;
  }

  .main-page-video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

export { VideoBlock };
