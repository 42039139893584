import React from 'react';
import styled from 'styled-components';
import { ContentWrapper } from '../../styled';
import { pxToStatic19Rem } from '../../styles';
import { DetailsBlock } from '../blocks';

const ThirdSection = () => (
  <Wrapper id={'details'}>
    <DetailsBlock />
  </Wrapper>
);

const Wrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  padding-top: ${pxToStatic19Rem(120)};
  padding-bottom: ${pxToStatic19Rem(48)};
  background: linear-gradient(180deg, #15161b 0%, rgba(21, 22, 27, 0) 100%);
  background-size: 100% 280px;
  background-repeat: no-repeat;
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    padding-top: ${pxToStatic19Rem(48)};
  }
`;

export { ThirdSection };
