import React from 'react';
import styled from 'styled-components';
import { TournamentBlock, VideoBlock } from '../blocks';

const SecondSection = () => (
  <Wrapper>
    <TournamentBlock />
    <VideoBlock />
  </Wrapper>
);

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
`;

export { SecondSection };
