import React, { useRef, useMemo, useEffect, ComponentProps } from 'react';
import styled from 'styled-components';
import { useTrail, config, animated } from 'react-spring';
import singleChip from '../assets/img/single-chip.svg';
import chipsShadow from '../assets/img/chips-shadow.png';
import { useOnScreen } from '../hooks';
import { pxToStatic19Rem } from '../styles';

const Chips = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const onScreen = useOnScreen(wrapperRef, {
    threshold: 1,
  });
  const items = useMemo(
    () => [
      (props: ComponentProps<typeof Chip>) => (
        <MiddleChip src={singleChip} alt={'Chip 2'} {...props} />
      ),
      (props: ComponentProps<typeof Chip>) => (
        <TopChip src={singleChip} alt={'Chip 3'} {...props} />
      ),
    ],
    [],
  );
  const [trails, api] = useTrail(items.length, () => ({
    config: { ...config.wobbly },
    from: { opacity: 0, y: 120 },
    opacity: 1,
    y: 0,
    pause: true,
  }));

  useEffect(() => {
    onScreen && api.resume();
  }, [onScreen, api]);

  return (
    <Wrapper ref={wrapperRef}>
      <ChipShadow src={chipsShadow} aria-hidden />
      <BottomChip src={singleChip} alt={'Chip 1'} />
      {trails.map(({ y, ...rest }, index) => {
        const Component = items[index];
        return (
          <Component
            key={index}
            style={{
              ...rest,
              transform: y.to((y) => `translate3d(0,-${y}px,0)`),
            }}
          />
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex-shrink: 0;
  align-self: flex-start;
  width: ${pxToStatic19Rem(280)};
  margin-top: ${pxToStatic19Rem(48)};
  margin-right: ${pxToStatic19Rem(62)};
  position: relative;
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    width: ${pxToStatic19Rem(168)};
    margin-top: ${pxToStatic19Rem(30)};
  }
  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    margin-right: 18px;
  }
  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    width: 200px;
    align-self: center;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const Chip = styled(animated.img)`
  position: absolute;
  width: 100%;
  height: auto;
`;
const TopChip = styled(Chip)`
  bottom: ${pxToStatic19Rem(64)};
  left: -12px;
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    bottom: ${pxToStatic19Rem(30)};
    left: -8px;
  }
`;
const MiddleChip = styled(Chip)`
  bottom: ${pxToStatic19Rem(32)};
  left: -30px;
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    bottom: ${pxToStatic19Rem(14)};
    left: -10px;
  }
`;
const BottomChip = styled(Chip)`
  position: relative;
`;
const ChipShadow = styled.img`
  position: absolute;
  right: -40px;
  bottom: -${pxToStatic19Rem(56)};
  width: 100%;
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    right: -${pxToStatic19Rem(20)};
    bottom: -${pxToStatic19Rem(30)};
  }
  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    right: -${pxToStatic19Rem(24)};
    bottom: -${pxToStatic19Rem(46)};
  }
  }
`;

export { Chips };
