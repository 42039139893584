import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { resetListStyles } from '../styles';
import { SocialLinks } from '../utils/consts';
import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  TelegramIcon,
  YoutubeIcon,
  RedditIcon,
  MediumIcon,
} from './icons';

type SocialIconsProps = {
  big?: boolean;
} & React.HTMLAttributes<HTMLUListElement>;

const SocialIcons = ({ big, ...props }: SocialIconsProps) => (
  <List {...props}>
    {icons.map(({ Icon, label, link }) => (
      <ListItem key={label}>
        <Button $big={big} aria-label={label} onClick={() => window.open(link)}>
          <Icon />
        </Button>
      </ListItem>
    ))}
  </List>
);

const List = styled.ul`
  ${resetListStyles};

  display: flex;
`;

const ListItem = styled.li``;

const Button = styled(IconButton)<{
  $big?: boolean;
}>`
  padding: ${({ $big }) => ($big ? 7 : 5)}px;
  .MuiSvgIcon-root {
    width: ${({ $big }) => ($big ? 42 : 32)}px;
    height: ${({ $big }) => ($big ? 42 : 32)}px;
    &:hover {
      color: ${({
        theme: {
          palette: {
            primary: { main },
          },
        },
      }) => main};
    }
  }
`;

const icons = [
  { Icon: FacebookIcon, label: 'Facebook', link: SocialLinks.FACEBOOK },
  { Icon: InstagramIcon, label: 'Instagram', link: SocialLinks.INSTAGRAM },
  { Icon: TwitterIcon, label: 'Twitter', link: SocialLinks.TWITTER },
  { Icon: TelegramIcon, label: 'Telegram', link: SocialLinks.TELEGRAM },
  { Icon: YoutubeIcon, label: 'Youtube', link: SocialLinks.YOUTUBE },
  { Icon: RedditIcon, label: 'Reddit', link: SocialLinks.REDDIT },
  { Icon: MediumIcon, label: 'Medium', link: SocialLinks.MEDIUM },
] as const;

export { SocialIcons };
