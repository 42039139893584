export const developmentMode = process.env.NODE_ENV !== 'production';
export const defaultLanguageLocale = 'en';
export enum SocialLinks {
  FACEBOOK = 'https://www.facebook.com/influencerpoker/',
  INSTAGRAM = 'https://www.instagram.com/inpoker2.0/',
  TWITTER = 'https://www.twitter.com/influencerpoker',
  TELEGRAM = 'https://t.me/inpokergroup',
  YOUTUBE = 'https://youtube.com/channel/UCkWI8-p2lGomTeuvFM7kV5Q',
  REDDIT = 'https://www.reddit.com/r/InPoker/',
  MEDIUM = 'https://inpoker.medium.com/',
}
export enum Links {
  MORE_INFORMATION = 'https://nft.inpoker.io',
  WHITEPAPER = 'https://drive.google.com/file/d/1sXOc58LPXaNb-X-2zMWh6QO9_NipwI8n/view?usp=sharing',
  TESTNET_CACHIER = 'https://google.com',
  PLAY_POKER = 'https://play.inpoker.io/',
  WELCOME_BONUS = 'https://t.me/InPokerBot',
  NFT_CARD = 'https://nft.inpoker.io',
  MARKETPLACE = 'https://marketplace.inpoker.io',
}
export const mainPageVideoId = !developmentMode ? 'zsSywRgKzL8' : '';
