import { animated } from '@react-spring/web';
import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSlideInAnimation } from '../../hooks';
import { pxToStatic19Rem } from '../../styles';
import { Chips } from '../Chips';
import { Text, TextProps } from '../texts';

const TournamentBlock = () => {
  const { t } = useTranslation();
  const [wrapper, trails] = useSlideInAnimation<HTMLDivElement>({
    amount: 1,
  });
  const { opacity, y } = trails[0];

  return (
    <Wrapper>
      <TopContent>
        <Chips />
        <TextContent ref={wrapper}>
          <Title
            style={{
              opacity,
              transform: y.to((y) => `translate3d(0,${y}px,0)`),
            }}
          >
            {t('INDEX_PAGE_TOURNAMENT_BLOCK__title')}
          </Title>
          <DesktopText>{t('INDEX_PAGE_TOURNAMENT_BLOCK__text')}</DesktopText>
        </TextContent>
      </TopContent>
      <MobileText aria-hidden>
        {t('INDEX_PAGE_TOURNAMENT_BLOCK__text')}
      </MobileText>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: ${pxToStatic19Rem(60)};
  padding-bottom: ${pxToStatic19Rem(30)};
  padding-left: ${pxToStatic19Rem(130)};
  padding-right: ${pxToStatic19Rem(160)};
  border-top: 2px solid
    ${({
      theme: {
        palette: {
          primary: { main },
        },
      },
    }) => main};
  border-bottom: 2px solid
    ${({
      theme: {
        palette: {
          primary: { main },
        },
      },
    }) => main};

  background: ${({ theme: { getColor } }) => css`
    linear-gradient(118.09deg, ${getColor(
      'naturalGray',
    )} -135.08%, #232627 90.37%)
  `};

  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    padding-top: ${pxToStatic19Rem(30)};
    padding-left: ${pxToStatic19Rem(26)};
    padding-right: ${pxToStatic19Rem(26)};
  }
`;
const TopContent = styled.div`
  display: flex;
  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    flex-direction: column;
  }
`;
const TextContent = styled.div`
  margin-right: 20px;
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    margin-right: 0;
  }
`;
const Title = styled(
  animated((props: TextProps) => (
    <Text
      variant={'h2'}
      component={'h2'}
      color={'primary'}
      uppercase
      {...props}
    />
  )),
)`
  margin-bottom: ${pxToStatic19Rem(24)};
  font-weight: 700;
  font-variation-settings: 'wght' 700;
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
`;
const DesktopText = styled(Text)`
  line-height: 1.25;
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    display: none;
  }
`;
const MobileText = styled(Text)`
  margin-top: ${pxToStatic19Rem(16)};
  line-height: 1.25;
  ${({ theme: { getUpMedia } }) => getUpMedia('lg')} {
    display: none;
  }
`;

export { TournamentBlock };
