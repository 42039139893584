import { createMuiTheme } from '@material-ui/core';
import { colors } from './colors';
import { defaultFontSize, fallbackFonts } from './consts';

const primary = colors.galliano;
const secondary = colors.finch;
const error = colors.red;

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1680,
  },
};

// @ts-ignore
const helperTheme = createMuiTheme({
  breakpoints,
  typography: {
    htmlFontSize: defaultFontSize,
  },
});

export const theme = createMuiTheme({
  breakpoints,
  palette: {
    colors,
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    text: {
      primary: colors.white,
    },
    background: {
      default: colors.completelyBlack,
    },
    error: {
      main: error,
    },
  },
  typography: {
    htmlFontSize: defaultFontSize,
    fontFamily: `MulishVariable, ${fallbackFonts}`,
    h1: {
      fontSize: helperTheme.typography.pxToRem(44.8),
      fontWeight: 700,
      fontVariationSettings: "'wght' 700",
      color: primary,
      lineHeight: 1.25,
      [helperTheme.breakpoints.down('lg')]: {
        fontSize: helperTheme.typography.pxToRem(34),
      },
      [helperTheme.breakpoints.down('sm')]: {
        fontSize: helperTheme.typography.pxToRem(33),
      },
    },
    h2: {
      fontSize: helperTheme.typography.pxToRem(28.8),
      fontWeight: 900,
      fontVariationSettings: "'wght' 900",
      color: colors.white,
      lineHeight: 1.25,
      [helperTheme.breakpoints.down('xs')]: {
        fontSize: helperTheme.typography.pxToRem(18),
      },
    },
    h3: {
      fontSize: helperTheme.typography.pxToRem(24),
      fontWeight: 700,
      fontVariationSettings: "'wght' 700",
      lineHeight: 1.25,
      [helperTheme.breakpoints.down('lg')]: {
        fontSize: helperTheme.typography.pxToRem(20),
      },
      [helperTheme.breakpoints.down('sm')]: {
        fontSize: helperTheme.typography.pxToRem(18),
      },
    },
    body1: {
      fontSize: '1rem', // 24px
      lineHeight: 1.5,
      [helperTheme.breakpoints.down('lg')]: {
        fontSize: helperTheme.typography.pxToRem(19),
      },
      [helperTheme.breakpoints.down('sm')]: {
        fontSize: helperTheme.typography.pxToRem(14),
      },
    },
    body2: {
      fontSize: '0.75rem', // 18px
      lineHeight: 1.25,
      [helperTheme.breakpoints.down('sm')]: {
        fontSize: helperTheme.typography.pxToRem(13),
      },
    },
  },
  overrides: {
    MuiTypography: {
      paragraph: {
        marginBottom: 24,
      },
    },
    MuiLink: {
      root: {
        color: colors.white,
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
    MuiButton: {
      root: {
        fontSize: helperTheme.typography.pxToRem(19),
        lineHeight: 1.2,
      },
      contained: {
        borderRadius: 5,
        color: colors.white,
        minWidth: 259,
        padding: '22px 19px 22px',
        [helperTheme.breakpoints.down('md')]: {
          padding: '21px 19px 21px',
        },
      },
      containedPrimary: {
        color: colors.white,
        background: `linear-gradient(307.47deg, #946824 -7.52%, #A77F2F 33.48%, #C5A241 98.02%);`,
        '&:hover': {
          background: `linear-gradient(307.47deg, #C5A241 -7.52%, #A77F2F 33.48%, #946824 98.02%);`,
        },
      },
      containedSecondary: {
        color: colors.white,
      },
    },
  },
  props: {
    MuiButton: {
      variant: 'contained',
      color: 'primary',
    },
  },
  getColor: (colorName) => colors[colorName],
  getDownMedia:
    (breakPoint) =>
    ({ theme }) =>
      theme.breakpoints.down(breakPoint),
  getUpMedia:
    (breakPoint) =>
    ({ theme }) =>
      theme.breakpoints.up(breakPoint),
});
