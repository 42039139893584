import { animated } from '@react-spring/web';
import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSlideInAnimation } from '../../hooks';
import { pxToStatic19Rem } from '../../styles';
import { Text } from '../texts';
import { PurchaseButton } from '../buttons';
import cardBunch from '../../assets/img/cardbunch.svg';

const LimitedEditionBlock = () => {
  const { t } = useTranslation();
  const [wrapper, trails] = useSlideInAnimation<HTMLDivElement>({
    amount: 1,
  });
  const { opacity, y } = trails[0];

  return (
    <Wrapper>
      <Title variant={'h2'} uppercase align={'center'}>
        {t('INDEX_PAGE_LIMITED_EDITION_BLOCK__title')}
      </Title>
      <Content>
        <FirstSection>
          <Img src={cardBunch} alt={'Card bunch'} />
        </FirstSection>
        <SecondSection ref={wrapper}>
          <SubTitle
            variant={'h2'}
            component={'h3'}
            color={'primary'}
            style={{
              opacity,
              transform: y.to((y) => `translate3d(0,${y}px,0)`),
            }}
          >
            {t('INDEX_PAGE_LIMITED_EDITION_BLOCK__subTitle')}
          </SubTitle>
          <Desc>{t('INDEX_PAGE_LIMITED_EDITION_BLOCK__text')}</Desc>
          <Button />
        </SecondSection>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div``;
const Title = styled(Text)`
  margin-bottom: ${pxToStatic19Rem(60)};
  ${({ theme: { getDownMedia } }) => getDownMedia(1500)} {
    margin-bottom: ${pxToStatic19Rem(50)};
  }
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    margin-bottom: ${pxToStatic19Rem(30)};
  }
  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    margin-bottom: ${pxToStatic19Rem(20)};
  }
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: ${pxToStatic19Rem(75)};
  padding-right: ${pxToStatic19Rem(60)};
  border: 1px solid #7a8293;
  border-radius: 15px;
  background: ${({ theme: { getColor } }) => css`
    linear-gradient(118.09deg, ${getColor('naturalGray')} -135.08%, ${getColor(
    'shark',
  )} 90.37%)
  `};

  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    flex-direction: column;
    align-items: center;
    padding-bottom: ${pxToStatic19Rem(50)};
    padding-left: ${pxToStatic19Rem(35)};
    padding-right: ${pxToStatic19Rem(35)};
  }
`;
const FirstSection = styled.div`
  flex: 0 0 auto;
`;
const Img = styled.img`
  width: ${pxToStatic19Rem(543)};
  height: auto;
  position: relative;
  top: -14px;
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    right: 0;
  }
`;
const SecondSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${pxToStatic19Rem(36)};
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    margin-left: 0;
  }
`;
const SubTitle = styled(animated(Text))`
  margin-bottom: ${pxToStatic19Rem(18)};
`;
const Desc = styled(Text)`
  margin-top: 10px;
`;
const Button = styled(PurchaseButton)`
  align-self: flex-start;
  margin-top: ${pxToStatic19Rem(54)};
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    align-self: center;
  }
`;

export { LimitedEditionBlock };
