import React from 'react';
import { Icon, IconProps } from './Icon';

export type InstagramIconProps = IconProps;

const InstagramIcon = (props: InstagramIconProps) => (
  <Icon viewBox="0 0 42 41" fill="none" {...props}>
    <path
      d="M20.747 40.241c-10.836 0-19.62-8.784-19.62-19.62C1.116 9.784 9.9 1 20.746 1c10.836 0 19.62 8.784 19.62 19.62 0 10.837-8.784 19.621-19.62 19.621z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <path
      d="M20.747 11.805c2.877 0 3.217.01 4.351.062 1.052.051 1.619.227 2 .371.506.196.856.433 1.238.804.381.382.608.732.804 1.238.144.381.32.948.37 2 .052 1.134.063 1.474.063 4.35 0 2.877-.02 3.207-.072 4.341-.052 1.052-.227 1.62-.372 2a3.426 3.426 0 01-.804 1.238 3.426 3.426 0 01-1.237.804c-.382.144-.949.32-2 .371-1.134.052-1.475.062-4.351.062-2.877 0-3.217-.01-4.351-.062-1.052-.051-1.619-.227-2-.37a3.427 3.427 0 01-1.238-.805 3.296 3.296 0 01-.804-1.237c-.144-.382-.32-.949-.371-2-.052-1.135-.062-1.475-.062-4.352 0-2.876.01-3.216.062-4.35.052-1.052.227-1.62.371-2 .196-.506.433-.856.804-1.238a3.296 3.296 0 011.238-.804c.381-.144.948-.32 2-.371 1.144-.042 1.485-.052 4.361-.052zm0-1.938c-2.918 0-3.289.01-4.433.062-1.145.051-1.928.237-2.609.505a5.28 5.28 0 00-1.907 1.237 5.149 5.149 0 00-1.238 1.908c-.268.68-.443 1.464-.505 2.608-.051 1.144-.062 1.516-.062 4.433 0 2.918.01 3.29.062 4.434.052 1.144.237 1.928.505 2.608.279.712.64 1.31 1.238 1.908a5.15 5.15 0 001.907 1.237c.68.268 1.464.443 2.609.505 1.144.052 1.515.062 4.433.062s3.29-.01 4.433-.062c1.145-.051 1.929-.237 2.609-.505a5.282 5.282 0 001.907-1.237 5.15 5.15 0 001.238-1.908c.268-.68.443-1.464.505-2.608.052-1.144.062-1.516.062-4.433 0-2.918-.01-3.29-.062-4.434-.052-1.144-.237-1.928-.505-2.609a5.282 5.282 0 00-1.238-1.907 5.148 5.148 0 00-1.907-1.237c-.68-.268-1.464-.443-2.609-.505-1.154-.052-1.515-.062-4.433-.062z"
      fill="currentColor"
    />
    <path
      d="M20.747 15.094a5.527 5.527 0 105.526 5.527c-.01-3.052-2.484-5.527-5.526-5.527zm0 9.115a3.59 3.59 0 01-3.588-3.588 3.59 3.59 0 013.588-3.588 3.59 3.59 0 013.588 3.587c-.01 1.98-1.608 3.588-3.588 3.588zM27.779 14.878a1.29 1.29 0 11-2.579-.001 1.29 1.29 0 012.579 0z"
      fill="currentColor"
    />
  </Icon>
);

export { InstagramIcon };
