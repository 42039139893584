import styled from 'styled-components';

export const ContentWrapper = styled.section`
  min-height: 100vh;
  padding: 0 130px 0 160px;

  ${({ theme: { getDownMedia } }) => getDownMedia('lg')} {
    padding: 0 80px 0 100px;
  }

  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    padding: 0 110px;
  }

  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    padding: 0 40px;
  }

  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    padding: 0 26px;
  }
`;
