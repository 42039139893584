import { animated } from '@react-spring/web';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSlideInAnimation } from '../../hooks';
import { pxToStatic19Rem, resetListStyles } from '../../styles';
import { Text } from '../texts';
import cards from '../../assets/img/icons/cards.svg';
import handshake from '../../assets/img/icons/handshake.svg';
import incomes from '../../assets/img/icons/incomes.svg';
import recycle from '../../assets/img/icons/recycle.svg';
import sputnik from '../../assets/img/icons/sputnik.svg';
import tickets from '../../assets/img/icons/tickets.svg';

const DetailsBlock = () => {
  const { t } = useTranslation();
  const [wrapper, trails] = useSlideInAnimation<HTMLUListElement>({
    amount: items.length,
  });

  return (
    <>
      <Title variant={'h2'} uppercase align={'center'}>
        {t('INDEX_PAGE_BOTTOM_SECTION__title')}
      </Title>
      <List ref={wrapper}>
        {trails.map(({ y, ...rest }, index) => {
          const { icon, title, text } = items[index];
          return (
            <ListItem
              key={index}
              style={{
                ...rest,
                transform: y.to((y) => `translate3d(0,${y}px,0)`),
              }}
            >
              <ListItemIconWrapper aria-hidden>
                <ListItemIcon src={icon} />
              </ListItemIconWrapper>
              <ListItemTextContent>
                <ListItemTitle variant={'h3'}>{t(title)}</ListItemTitle>
                <ListItemText>{t(text)}</ListItemText>
              </ListItemTextContent>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

type ItemProperties = 'icon' | 'title' | 'text';

const items: Array<
  {
    [key in ItemProperties]: string;
  }
> = [
  {
    icon: sputnik,
    title: 'INDEX_PAGE_BOTTOM_SECTION__satelliteTitle',
    text: 'INDEX_PAGE_BOTTOM_SECTION__satelliteText',
  },
  {
    icon: tickets,
    title: 'INDEX_PAGE_BOTTOM_SECTION__freeTicketsTitle',
    text: 'INDEX_PAGE_BOTTOM_SECTION__freeTicketsText',
  },
  {
    icon: handshake,
    title: 'INDEX_PAGE_BOTTOM_SECTION__privilegesTitle',
    text: 'INDEX_PAGE_BOTTOM_SECTION__privilegesText',
  },
  {
    icon: cards,
    title: 'INDEX_PAGE_BOTTOM_SECTION__freeWeeklyTitle',
    text: 'INDEX_PAGE_BOTTOM_SECTION__freeWeeklyText',
  },
  {
    icon: incomes,
    title: 'INDEX_PAGE_BOTTOM_SECTION__inPokerTitle',
    text: 'INDEX_PAGE_BOTTOM_SECTION__inPokerText',
  },
  {
    icon: recycle,
    title: 'INDEX_PAGE_BOTTOM_SECTION__abilityTitle',
    text: 'INDEX_PAGE_BOTTOM_SECTION__abilityText',
  },
];

const Title = styled(Text)`
  margin-bottom: ${pxToStatic19Rem(60)};
  ${({ theme: { getDownMedia } }) => getDownMedia(1500)} {
    margin-bottom: ${pxToStatic19Rem(50)};
  }
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    margin-bottom: ${pxToStatic19Rem(30)};
  }
  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    margin-bottom: ${pxToStatic19Rem(20)};
  }
`;
const List = styled.ul`
  ${resetListStyles};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: auto 0;
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    flex-direction: column;
  }
`;
const ListItem = styled(animated.li)`
  flex: 1 1 50%;
  display: flex;
  padding-top: ${pxToStatic19Rem(40)};
  padding-bottom: ${pxToStatic19Rem(40)};
  ${({ theme: { getUpMedia } }) => getUpMedia('lg')} {
    & + &:not(:nth-child(2)) {
      border-top: 1px dashed
        ${({ theme: { getColor } }) => getColor('shuttleGray')};
    }
    &:nth-child(odd) {
      padding-right: 20px;
    }
    &:nth-child(even) {
      border-left: 1px dashed
        ${({ theme: { getColor } }) => getColor('shuttleGray')};
      padding-left: ${pxToStatic19Rem(32)};
    }
  }
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    & + & {
      border-top: 1px dashed
        ${({ theme: { getColor } }) => getColor('shuttleGray')};
    }
  }
  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    padding-top: 22px;
    padding-bottom: 22px;
  }
`;
const ListItemIconWrapper = styled.div`
  flex-shrink: 0;
  align-self: flex-start;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${pxToStatic19Rem(100)};
  height: ${pxToStatic19Rem(100)};
  border: 4px solid ${({ theme: { getColor } }) => getColor('midGray')};
  border-radius: ${pxToStatic19Rem(20)};
  margin-right: ${pxToStatic19Rem(22)};
  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    width: ${pxToStatic19Rem(80)};
    height: ${pxToStatic19Rem(80)};
  }
`;
const ListItemIcon = styled.img`
  width: ${pxToStatic19Rem(60)};
  height: ${pxToStatic19Rem(60)};
  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    width: ${pxToStatic19Rem(40)};
    height: ${pxToStatic19Rem(40)};
  }
`;
const ListItemTextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const ListItemTitle = styled(Text)`
  margin-bottom: 5px;
`;
const ListItemText = styled(Text)`
  line-height: 1.25;
`;

export { DetailsBlock };
