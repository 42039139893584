import React from 'react';
import { Icon, IconProps } from './Icon';

export type TwitterIconProps = IconProps;

const TwitterIcon = (props: TwitterIconProps) => (
  <Icon viewBox="0 0 42 41" fill="none" {...props}>
    <path
      d="M20.873 40.241c-10.836 0-19.62-8.784-19.62-19.62C1.253 9.784 10.037 1 20.873 1 31.71 1 40.495 9.784 40.495 20.62c0 10.837-8.784 19.621-19.62 19.621z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <path
      d="M10.419 27.188a12.15 12.15 0 006.578 1.928c7.96 0 12.465-6.722 12.197-12.764a8.943 8.943 0 002.145-2.216 8.634 8.634 0 01-2.465.67 4.28 4.28 0 001.887-2.372 8.524 8.524 0 01-2.722 1.042 4.279 4.279 0 00-3.134-1.351c-2.774 0-4.805 2.588-4.176 5.268-3.567-.175-6.722-1.886-8.846-4.485a4.295 4.295 0 001.33 5.733 4.336 4.336 0 01-1.949-.536c-.04 1.99 1.382 3.846 3.444 4.258a4.22 4.22 0 01-1.938.072 4.3 4.3 0 004.01 2.98 8.666 8.666 0 01-6.361 1.773z"
      fill="currentColor"
    />
  </Icon>
);

export { TwitterIcon };
