import React from 'react';
import { Icon, IconProps } from './Icon';

export type FacebookIconProps = IconProps;

const FacebookIcon = (props: FacebookIconProps) => (
  <Icon viewBox="0 0 41 41" fill="none" {...props}>
    <path
      d="M20.62 40.241C9.785 40.241 1 31.457 1 20.621 1 9.784 9.784 1 20.62 1c10.837 0 19.621 8.784 19.621 19.62 0 10.837-8.784 19.621-19.62 19.621z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <path
      d="M17.63 32.478h4.774v-11.96h3.33l.351-4h-3.691v-2.28c0-.948.186-1.32 1.103-1.32h2.588V8.765h-3.31c-3.557 0-5.155 1.567-5.155 4.557v3.196h-2.485v4.052h2.485v11.909h.01z"
      fill="currentColor"
    />
  </Icon>
);

export { FacebookIcon };
