import React from 'react';
import {
  Button as DefButton,
  ButtonProps as DefButtonProps,
} from '@material-ui/core';

export type ButtonProps = DefButtonProps;

const Button = (props: ButtonProps) => <DefButton {...props} />;

export { Button };
