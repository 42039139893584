import React, {
  useReducer,
  useMemo,
  useContext,
  createContext,
  FC,
  Reducer,
  Dispatch,
  ComponentType,
} from 'react';
import styled, { css } from 'styled-components';
import { isObject } from 'lodash-es';
import { Modal as DefModal, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { ComingSoon } from '../components/modals';

const Modals: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { modalOpened, Content, data } = state;

  const contextValue = useMemo(() => ({ ...state, dispatch }), [state]);

  const closeModal = () => dispatch(['closeModal']);

  return (
    <ModalsContext.Provider value={contextValue}>
      {children}
      <Modal open={modalOpened} disableEnforceFocus onClose={closeModal}>
        <ContentWrapper>
          <CloseButton aria-label={'Close'} onClick={closeModal}>
            <Close />
          </CloseButton>
          <Content {...(isObject(data) ? data : {})} />
        </ContentWrapper>
      </Modal>
    </ModalsContext.Provider>
  );
};

const Modal = styled(DefModal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 470px;
  max-width: calc(100% - 20px);
  border: 1px solid
    ${({
      theme: {
        palette: {
          primary: { main },
        },
      },
    }) => main};
  border-radius: 15px;
  padding: 40px 30px 46px;
  ${({ theme: { getColor } }) => css`
    background: linear-gradient(
      118.09deg,
      ${getColor('naturalGray')} -135.08%,
      ${getColor('shark')} 90.37%
    );
  `};
  outline: none;
  position: relative;
`;

type State = {
  modalOpened: boolean;
  Content: ComponentType;
  data: unknown;
};

const initialState: State = {
  modalOpened: false,
  Content: () => null,
  data: undefined,
};

const modals = {
  comingSoon: ComingSoon,
} as const;

export type ModalContentType = keyof typeof modals;

type Action = ['closeModal'] | ['setModalContent', ModalContentType, unknown?];

const reducer: Reducer<State, Action> = (
  prevState,
  [type, modalType, data]
) => {
  switch (type) {
    case 'closeModal':
      return initialState;
    case 'setModalContent':
      const Content = !!modalType && modals[modalType];

      return !Content
        ? initialState
        : {
            modalOpened: true,
            Content,
            data,
          };
    default:
      return prevState;
  }
};

export const ModalsContext = createContext<
  State & {
    dispatch: Dispatch<Action>;
  }
>({
  ...initialState,
  dispatch: () => undefined,
});

export const useModals = () => useContext(ModalsContext);

export { Modals };
