import { createGlobalStyle } from 'styled-components';
import { defaultFontSize, fallbackFonts } from './consts';

const GlobalStyles = createGlobalStyle`
  html, body {
    max-width: 100%;
    background-color: ${({
      theme: {
        palette: { background },
      },
    }) => background.default};
    overflow-x: hidden;
    font-size: ${defaultFontSize}px;
    font-weight: 400;
    font-family: Mulish, ${fallbackFonts};
    line-height: 1.255;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${({ theme }) => theme.palette.text.primary};

    @supports (font-variation-settings: normal) {
      font-family: MulishVariable, ${fallbackFonts};
      font-variation-settings: "wght" 400;
    }
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  #root {
    min-height: 100vh;
  }

  img {
    display: block;
    max-width: 100%;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  p {
    margin: 0;
  }
`;

export { GlobalStyles };
