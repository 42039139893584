import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { pxToStatic19Rem } from '../../styles';
import { Text as DefText } from '../texts';
import tradeImg from '../../assets/img/cards-trade.png';

const TradeCards = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Left>
        <Title variant={'h2'} color={'primary'}>
          {t('INDEX_PAGE_TRADE_CARDS_BLOCK__title')}
        </Title>
        <Text paragraph>{t('INDEX_PAGE_TRADE_CARDS_BLOCK__text1')}</Text>
        <Text paragraph>{t('INDEX_PAGE_TRADE_CARDS_BLOCK__text2')}</Text>
        <Text paragraph>{t('INDEX_PAGE_TRADE_CARDS_BLOCK__text3')}</Text>
      </Left>
      <Right>
        <Img src={tradeImg} alt={'Trade'} />
      </Right>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${pxToStatic19Rem(33)};
  border-bottom: 1px dashed
    ${({ theme: { getColor } }) => getColor('shuttleGray')};

  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    flex-direction: column;
  }
`;
const Left = styled.div`
  flex: 1 1 auto;
  margin-right: ${pxToStatic19Rem(60)};
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    margin-right: 0;
  }
`;
const Title = styled(DefText)`
  margin-bottom: 20px;
`;
const Text = styled(DefText)``;
const Right = styled.div`
  flex: 0 0 52%;
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    margin-top: 30px;
    flex: 0 0 auto;
  }
`;
const Img = styled.img``;

export { TradeCards };
