import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../assets/i18n/en.json';
import { defaultLanguageLocale } from './consts';
// import moment from 'moment';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
  },
  lng: defaultLanguageLocale,
  fallbackLng: defaultLanguageLocale,
  interpolation: {
    escapeValue: false,
  },
});

/*moment.locale(defaultLanguageLocale);

i18n.on('languageChanged', function (lngLocale) {
  if (moment.locales().includes(lngLocale)) {
    moment.locale(lngLocale);
  } else {
    moment.locale(defaultLanguageLocale);
  }
});*/

export default i18n;
