import { MutableRefObject, useState, useEffect } from 'react';

export const useOnScreen = <T extends HTMLElement>(
  ref: MutableRefObject<T | null>,
  options?: IntersectionObserverInit,
): boolean => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState<boolean>(false);
  useEffect(() => {
    const savedRef = ref.current;
    const observer = new IntersectionObserver(([entry]) => {
      // Update our state when observer callback fires
      setIntersecting(entry.isIntersecting);
    }, options);
    if (savedRef) {
      observer.observe(savedRef);
    }
    return () => {
      savedRef && observer.unobserve(savedRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
};
