import React from 'react';
import styled, { css } from 'styled-components';
import { Typography, TypographyProps } from '@material-ui/core';
import { ColorName } from '../../styles';

export type TextProps = TypographyProps & {
  component?: React.ElementType;
  uppercase?: boolean;
  customColor?: ColorName;
};

const Text = ({ uppercase = false, customColor, ...props }: TextProps) => (
  <StyledTypography $uppercase={uppercase} $color={customColor} {...props} />
);

const StyledTypography = styled(Typography)<{
  $uppercase: boolean;
  $color: ColorName | undefined;
}>`
  ${({ $uppercase }) =>
    $uppercase &&
    css`
      text-transform: uppercase;
    `}

  ${({ theme: { getColor }, $color }) =>
    $color &&
    css`
      color: ${getColor($color)};
    `}
`;

export { Text };
