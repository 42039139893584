import React from 'react';
import { Link as DefLink, LinkProps as DefLinkProps } from '@material-ui/core';

export type LinkProps = DefLinkProps;

const Link = (props: LinkProps) => {
  const extraProps: LinkProps = !!props.href
    ? {
        rel: 'noopener noreferrer',
        target: '_blank',
      }
    : {};

  return <DefLink {...extraProps} {...props} />;
};

export { Link };
