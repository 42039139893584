import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { pxToStatic19Rem } from '../../styles';
import { Text as DefText } from '../texts';
import generationImg from '../../assets/img/cards-generation.png';

const CardsGeneration = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Left>
        <Title variant={'h2'} color={'primary'}>
          {t('INDEX_PAGE_TRADE_CARDS_GENERATION__title')}
        </Title>
        <Text>{t('INDEX_PAGE_TRADE_CARDS_GENERATION__text')}</Text>
      </Left>
      <Right>
        <Img src={generationImg} alt={'Generation'} />
      </Right>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${pxToStatic19Rem(61)};
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    flex-direction: column;
  }

  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    padding-top: ${pxToStatic19Rem(45)};
  }
`;
const Left = styled.div`
  flex: 1 1 auto;
  margin-right: ${pxToStatic19Rem(145)};
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    margin-bottom: 20px;
    margin-right: 0;
  }
`;
const Title = styled(DefText)`
  margin-bottom: 28px;
`;
const Text = styled(DefText)``;
const Right = styled.div`
  flex: 0 0 46.5%;
  position: relative;
  top: 17px;
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    margin-top: 30px;
    flex: 0 0 auto;
    position: static;
  }
`;
const Img = styled.img``;

export { CardsGeneration };
