export const colors = {
  white: '#FFFFFF',
  black: '#222',
  completelyBlack: '#000',
  brightGray: '#353C44',
  midGray: '#606061',
  shuttleGray: '#636870',
  woodSmoke: '#131419',
  armadillo: '#414136',
  codGray: '#0B0B0B',
  naturalGray: '#929185',
  shark: '#25292c',
  galliano: '#E9AF0D',
  finch: '#666545',
  lemonGrass: '#9D9D87',
  red: '#FF0404',
} as const;

export type ColorName = keyof typeof colors;
export type ColorValue = typeof colors[ColorName];
