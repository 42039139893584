import React from 'react';
import { Icon, IconProps } from './Icon';

export type YoutubeIconProps = IconProps;

const YoutubeIcon = (props: YoutubeIconProps) => (
  <Icon viewBox="0 0 42 41" fill="none" {...props}>
    <path
      d="M21.126 40.241c-10.836 0-19.62-8.784-19.62-19.62C1.506 9.784 10.29 1 21.126 1c10.837 0 19.621 8.784 19.621 19.62 0 10.837-8.784 19.621-19.62 19.621z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeMiterlimit={10}
    />
    <path
      d="M32.993 15.114c-.284-1.034-1.123-1.849-2.18-2.126-1.938-.518-9.685-.518-9.685-.518s-7.75 0-9.688.497c-1.04.278-1.896 1.113-2.18 2.147-.51 1.89-.51 5.808-.51 5.808s0 3.939.51 5.809c.284 1.034 1.123 1.849 2.18 2.126 1.959.517 9.688.517 9.688.517s7.747 0 9.685-.497a3.06 3.06 0 002.18-2.126c.51-1.89.51-5.808.51-5.808s.02-3.939-.51-5.829zm-14.333 9.43v-7.242l6.444 3.62-6.444 3.621z"
      fill="currentColor"
    />
  </Icon>
);

export { YoutubeIcon };
