import React from 'react';
import styled from 'styled-components';
import { LimitedEditionBlock } from '../blocks';

const FourthSection = () => (
  <Wrapper>
    <LimitedEditionBlock />
  </Wrapper>
);

const Wrapper = styled.section`
  padding: 0 130px;

  ${({ theme: { getDownMedia } }) => getDownMedia('lg')} {
    padding: 0 70px;
  }

  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    padding: 0 40px;
  }

  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    padding: 0 26px;
  }
`;

export { FourthSection };
