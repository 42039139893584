import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../texts';

const ComingSoon = () => {
  const { t } = useTranslation();
  return (
    <Text align={'center'} variant={'h2'}>
      {t('MODAL_COMING_SOON__title')}
    </Text>
  );
};

export { ComingSoon };
