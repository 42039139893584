import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';

export const useBbox = <T extends HTMLElement>() => {
  const ref = useRef<T>(null);
  const [bbox, setBbox] = useState<DOMRect | null>(null);

  const set = () =>
    // @ts-ignore
    setBbox(ref.current ? ref.current?.getBoundingClientRect() : null);

  useEffect(() => {
    set();
    window.addEventListener('resize', set);
    return () => window.removeEventListener('resize', set);
  }, []);

  return [bbox, ref] as const;
};
