import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useModals } from '../providers/Modals';
import { Links } from '../utils/consts';
import { resetListStyles, pxToRem } from '../styles';
import { SocialIcons } from './SocialIcons';
import { Text as DefText, Link as DefLink, TextProps } from './texts';

const Footer = () => {
  const { t } = useTranslation();
  const { dispatch } = useModals();
  const links = useMemo(() => getLinks(dispatch), [dispatch]);
  return (
    <Wrapper>
      <FirstPart>
        <Text component={'h3'} uppercase>
          {t('FOOTER_LEFT_PART__title')}
        </Text>
        <Text paragraph>{t('FOOTER_LEFT_PART__desc')}</Text>
        <Text>{t('FOOTER_LEFT_PART__copy')}</Text>
      </FirstPart>
      <SecondPart>
        <LinksList>
          {links.map(({ title, ...props }) => (
            <LinksListItem key={title}>
              <Link {...props}>{t(title)}</Link>
            </LinksListItem>
          ))}
        </LinksList>
      </SecondPart>
      <ThirdPart>
        <SocialIcons big />
      </ThirdPart>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${pxToRem(80)} ${pxToRem(112)} ${pxToRem(90)} ${pxToRem(108)};
  background: ${({ theme: { getColor } }) => css`
    linear-gradient(168.37deg, ${getColor(
      'completelyBlack',
    )} -120.96%, ${getColor('shark')} 128.33%)
  `};
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    flex-direction: column;
    align-items: flex-start;
    padding-top: ${pxToRem(74)};
    padding-bottom: ${pxToRem(78)};
  }
  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    padding-top: ${pxToRem(30)};
    padding-bottom: ${pxToRem(30)};
    padding-left: ${pxToRem(20)};
    padding-right: ${pxToRem(20)};
  }
`;
const FirstPart = styled.section`
  flex: 0 0 40%;
  margin-right: 20px;
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    order: 2;
  }
`;
const Text = styled((props: TextProps) => (
  <DefText variant={'body2'} {...props} />
))`
  &.MuiTypography-paragraph {
    margin-bottom: ${pxToRem(20)};
    line-height: 1.28571429;
  }
`;
const Link = styled(DefLink)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
const SecondPart = styled.section`
  margin-right: ${pxToRem(44)};
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    margin-right: 0;
    margin-bottom: ${pxToRem(60)};
  }
  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    margin-bottom: ${pxToRem(20)};
  }
  ${Link} {
    color: ${({
      theme: {
        palette: {
          primary: { main },
        },
      },
    }) => main};
  }
`;
const LinksList = styled.ul`
  ${resetListStyles};
`;
const LinksListItem = styled.li``;
const ThirdPart = styled.section`
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    order: 1;
    margin-left: -8px;
    margin-bottom: ${pxToRem(63)};
  }
  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    margin-bottom: ${pxToRem(20)};
  }
`;

const getLinks = (modalsDispatch: ReturnType<typeof useModals>['dispatch']) =>
  [
    {
      title: 'FOOTER_MIDDLE_PART__playPokerLink',
      href: Links.PLAY_POKER,
    },
    { title: 'FOOTER_MIDDLE_PART__bonusLink', href: Links.WELCOME_BONUS },
    { title: 'FOOTER_MIDDLE_PART__NFTCardLink', href: Links.NFT_CARD },
    { title: 'FOOTER_MIDDLE_PART__whitePaperLink', href: Links.WHITEPAPER },
    {
      title: 'FOOTER_MIDDLE_PART__testnetLink',
      onClick: () => modalsDispatch(['setModalContent', 'comingSoon']),
    },
  ] as const;

export { Footer };
