import React, { useEffect } from 'react';
import { UseSpringProps } from 'react-spring';
import styled from 'styled-components';
import { useSpring, animated, to } from '@react-spring/web';
import { useGesture } from 'react-use-gesture';
import { useBbox, useIsTablet } from './hooks';
import card from './assets/img/card.svg';
import { pxToStatic19Rem } from './styles';

const Card = () => {
  const [imgData, imgRef] = useBbox<HTMLImageElement>();
  const isTablet = useIsTablet();

  useEffect(() => {
    const preventDefault = (e: Event) => e.preventDefault();
    document.addEventListener('gesturestart', preventDefault);
    document.addEventListener('gesturechange', preventDefault);

    return () => {
      document.removeEventListener('gesturestart', preventDefault);
      document.removeEventListener('gesturechange', preventDefault);
    };
  }, []);
  const [wrapperAnimation, wrapperAnimationApi] = useSpring(
    () => wrapperAnimationProps,
  );

  const [{ rotateX, rotateY, rotateZ, zoom, scale }, imgApi] = useSpring(
    () => ({
      rotateX: 0,
      rotateY: 0,
      rotateZ: 0,
      scale: 1,
      zoom: 0,
      config: { mass: 5, tension: 350, friction: 40 },
    }),
  );

  useGesture(
    {
      onMove: ({ xy: [px, py] }) => {
        if (!imgData) return;
        wrapperAnimationApi.stop();
        wrapperAnimationApi({ rotateY: 0 });
        imgApi({
          rotateX: calcX({
            cursor: py,
            size: imgData.height,
            offset: imgData.y,
          }),
          rotateY: calcY({
            cursor: px,
            size: imgData.width,
            offset: imgData.x,
          }),
          scale: 1.05,
        });
      },
      onHover: ({ hovering }) => {
        if (hovering) return;
        imgApi({ rotateX: 0, rotateY: 0, scale: 1 });
        wrapperAnimationApi.start(wrapperAnimationProps);
      },
    },
    { domTarget: imgRef, eventOptions: { passive: false } },
  );

  return (
    <Wrapper
      style={{
        transform: 'perspective(600px)',
        ...wrapperAnimation,
      }}
    >
      <Img
        ref={imgRef}
        src={card}
        alt={'Card'}
        style={{
          transform: 'perspective(600px)',
          ...(isTablet
            ? {
                scale: 1,
                rotateX: 0,
                rotateY: 0,
                rotateZ: 0,
              }
            : {
                scale: to([scale, zoom], (s, z) => s + z),
                rotateX,
                rotateY,
                rotateZ,
              }),
        }}
      />
    </Wrapper>
  );
};

const rotationDegree = 10;
const wrapperAnimationProps: UseSpringProps = {
  from: {
    rotateY: 0,
  },
  to: async (next) => {
    await next({ rotateY: -rotationDegree });
    await next({ rotateY: rotationDegree });
  },
  loop: true,
};

type CalcParams = {
  cursor: number;
  offset: number;
  size: number;
};
const coefficient = 14;
const calcX = ({ cursor, offset, size }: CalcParams) => {
  const y = cursor - offset;
  return (size / 2 - y) / coefficient;
};
const calcY = ({ cursor, offset, size }: CalcParams) => {
  const x = cursor - offset;
  return -(size / 2 - x) / coefficient;
};

const Wrapper = styled(animated.div)`
  width: ${pxToStatic19Rem(332)};
  height: ${pxToStatic19Rem(548)};
  position: relative;
  top: -4px;
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    width: 500px;
    height: 800px;
  }

  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    width: 290px;
    height: 440px;
  }
`;

const Img = styled(animated.img)`
  width: 100%;
  height: 100%;
`;

export { Card };
