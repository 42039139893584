import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { animated } from 'react-spring';
import useScrollTo from 'react-spring-scroll-to-hook';
import { Card } from '../../Card';
import { useSlideInAnimation } from '../../hooks';
import { resetListStyles, pxToStatic19Rem } from '../../styles';
import { ContentWrapper } from '../../styled';
import { Text as DefText, TextProps } from '../texts';
import {
  Button as DefButton,
  PurchaseButton as DefPurchaseButton,
} from '../buttons';
import topSectionBG from '../../assets/img/top-section-bg.png';
import logo from '../../assets/img/elite_logo.svg';

const TopSection = () => {
  const { t } = useTranslation();
  const { scrollTo } = useScrollTo();
  const items = useMemo(
    () => [
      {
        Component: Title,
        content: t('INDEX_PAGE_TOP_SECTION__title'),
      },
      {
        Component: Text,
        content: t('INDEX_PAGE_TOP_SECTION__p1'),
      },
      {
        Component: Text,
        content: t('INDEX_PAGE_TOP_SECTION__p2'),
      },
      {
        Component: Text,
        content: t('INDEX_PAGE_TOP_SECTION__p3'),
      },
      {
        Component: ButtonsList,
        content: (
          <>
            <li>
              <PurchaseButton />
            </li>
            <li>
              <Button
                color={'secondary'}
                onClick={() => scrollTo(document.querySelector('#details'))}
              >
                {t('INDEX_PAGE_TOP_SECTION__moreInformationButton')}
              </Button>
            </li>
          </>
        ),
      },
    ],
    [scrollTo, t],
  );
  const [wrapperRef, trails] = useSlideInAnimation<HTMLDivElement>({
    amount: items.length,
  });

  return (
    <Wrapper>
      <Header>
        <Logo src={logo} alt={'Elite Logo'} />
      </Header>
      <Content>
        <ContentFirst ref={wrapperRef}>
          {trails.map(({ y, ...rest }, index) => {
            const { Component, content } = items[index];
            return (
              // @ts-ignore
              <Component
                key={index}
                style={{
                  ...rest,
                  transform: y.to((y) => `translate3d(0,${y}px,0)`),
                }}
              >
                {content}
              </Component>
            );
          })}
        </ContentFirst>
        <ContentSeconds>
          <Card />
        </ContentSeconds>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 48px;
  background-image: url(${topSectionBG});
  background-position: bottom right;
  background-repeat: no-repeat;

  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    background-position: bottom 290px right 0;
  }

  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    padding-top: 20px;
    background-position: bottom 400px right 0;
  }
`;

const Header = styled.header`
  padding-top: 53px;
  padding-bottom: 50px;

  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    padding-top: 43px;
  }

  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    padding-top: 20px;
    padding-bottom: 4px;
  }
`;
const Logo = styled.img`
  width: ${pxToStatic19Rem(164)};
  height: ${pxToStatic19Rem(56)};
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    flex-direction: column-reverse;
    height: ${pxToStatic19Rem(46)};
  }

  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    width: ${pxToStatic19Rem(94)};
    height: ${pxToStatic19Rem(36)};
  }
`;
const Content = styled.div`
  display: flex;
  margin: auto 0;
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    flex-direction: column-reverse;
    align-items: center;
  }
`;
const ContentFirst = styled.div`
  padding-top: ${pxToStatic19Rem(70)};
  margin-right: ${pxToStatic19Rem(190)};
  ${({ theme: { getDownMedia } }) => getDownMedia('lg')} {
    margin-right: ${pxToStatic19Rem(100)};
  }
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    padding-top: 0;
    margin-right: 0;
    margin-top: -24px;
  }
  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    margin-top: -4px;
  }
`;
const Title = styled(
  animated((props: TextProps) => (
    <DefText variant={'h1'} uppercase {...props} />
  )),
)`
  margin-bottom: ${pxToStatic19Rem(22)};
  will-change: transform, opacity;

  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    margin-bottom: ${pxToStatic19Rem(10)};
  }
`;
const Text = styled(
  animated((props: TextProps) => <DefText paragraph {...props} />),
)`
  will-change: transform, opacity;
`;
const ContentSeconds = styled.div`
  flex-shrink: 0;
  margin-left: auto;
  margin-right: ${pxToStatic19Rem(88)};
  ${({ theme: { getDownMedia } }) => getDownMedia('lg')} {
    margin-right: 10px;
  }
  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    margin: 0;
  }
`;
const ButtonsList = styled(animated.ul)`
  ${resetListStyles};
  display: flex;
  margin-top: ${pxToStatic19Rem(36)};
  will-change: transform, opacity;

  ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
    padding: 0 16px;
    margin-top: ${pxToStatic19Rem(72)};
    justify-content: space-between;
  }

  ${({ theme: { getDownMedia } }) => getDownMedia('sm')} {
    padding: 0;
    margin-top: ${pxToStatic19Rem(19)};
  }

  ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
    flex-direction: column;
  }

  li {
    ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
      flex-grow: 1;
    }
  }

  li + li {
    margin-left: 30px;

    ${({ theme: { getDownMedia } }) => getDownMedia('md')} {
      margin-left: ${pxToStatic19Rem(40)};
    }

    ${({ theme: { getDownMedia } }) => getDownMedia('xs')} {
      margin-left: 0;
      margin-top: ${pxToStatic19Rem(16)};
    }
  }
`;

const Button = styled(DefButton)`
  width: 100%;
`;

const PurchaseButton = styled(DefPurchaseButton)`
  width: 100%;
`;

export { TopSection };
