import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useModals } from '../../providers/Modals';
import { Button, ButtonProps } from './Button';
import coin from '../../assets/img/binance-coin-logo.png';
import { Links } from '../../utils/consts';

export type PurchaseButtonProps = ButtonProps;

const PurchaseButton = (props: PurchaseButtonProps) => {
  const { t } = useTranslation();
  const { dispatch } = useModals();
  return (
    <Button
      onClick={() => window.open(Links.MARKETPLACE)}
      color={'primary'}
      {...props}
    >
      <ButtonIcon src={coin} alt={'Binance icon'} />
      {t('PURCHASE_BUTTON')}
    </Button>
  );
};

const ButtonIcon = styled.img`
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 12px;
`;

export { PurchaseButton };
